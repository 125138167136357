'use client'
import { type FC, useState } from 'react'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import styled from 'styled-components'
import { randomNumberInRange } from '@/utils'
import { Nav } from '@/components'

import type { IHeader, IMain } from '@/components'

type Props = {
  paragraphs: any
  residential: any
  contract: any
}

const DynamicHeader = dynamic<IHeader>(() => import('@/components').then((mod) => mod.Header), { ssr: false })
const DynamicMain = dynamic<IMain>(() => import('@/components').then((mod) => mod.Main), { ssr: false })

export const DivHome: FC<Props> = ({ paragraphs, residential, contract }) => {
  const pathname = usePathname()
  const [idx] = useState(randomNumberInRange(0, (paragraphs.length - 1)))
  const [showSliderHomes, setShowSliderHomes] = useState(false)
  const [showSliderContracts, setShowSliderContracts] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)

  return (
    <>
      <DivOverlay className='overlay' $showOverlay={showOverlay} $pathname={pathname} />
      <DynamicHeader showSliderHomes={showSliderHomes} showSliderContracts={showSliderContracts} homes={residential} contracts={contract} />
      <Nav />
      <DynamicMain setShowSliderHomes={setShowSliderHomes} setShowSliderContracts={setShowSliderContracts} setShowOverlay={setShowOverlay} text={paragraphs[idx]} />
    </>
  )
}

const DivOverlay = styled.div<any>`
  background-color: ${({ $pathname }) => ($pathname === '/' || $pathname === '/es-ES' ) ? 'var(--color-darkgreen)' : 'var(--color-beige)'};
  min-height: 100vh;
  height: 100%;
  opacity: ${({ $showOverlay }) => $showOverlay ? 1 : 0};
  position: absolute;
  top: 0;
  transition: all 250ms linear;
  width: 100%;
  z-index: ${({ $showOverlay }) => $showOverlay ? 800 : 0};
`
